import React from 'react'
import Post from './Post'
// import Sidebar from '../Sidebar'
import { Container } from 'react-bootstrap'
import styled from 'styled-components' // 💅

export const Posts = ({ posts, postsComponentHeaderTitle }) => (
  <StyledPostsComponent>
    <Container fluid="md">
      {postsComponentHeaderTitle ? <h2> {postsComponentHeaderTitle} </h2> : ''}

      {posts.map(post => (
        <Post key={post.id} {...post}></Post>
      ))}
    </Container>
  </StyledPostsComponent>
)

const StyledPostsComponent = styled.div`
  h2 {
    color: #3a3335 !important;
    font-size: 2em;
    margin-bottom: 40px;
    padding-bottom: 0;
    text-decoration: none;
    font-family: Roboto, sans-serif;

    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
      text-decoration: none;
    }
  }
`
