import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import { Posts } from '../components/Posts/Posts'
import styled from 'styled-components' // 💅

const PostsPage = ({ data }) => {
  const {
    allMdx: { nodes: posts },
  } = data
  return (
    <Layout>
      <StyledPostsComponent>
        {posts && (
          <Posts
            posts={posts && posts}
            postsComponentHeaderTitle="All Publications"
          />
        )}
      </StyledPostsComponent>
    </Layout>
  )
}

export const query = graphql`
  {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        frontmatter {
          title
          # readTime
          category
          date(formatString: "MMMM DD, YYYY")
          # author
          slug
          # image {
          #   childImageSharp {
          #     fluid {
          #       ...GatsbyImageSharpFluid
          #     }
          #   }
          # }
        }
        excerpt
        id
      }
    }
  }
`

export default PostsPage

const StyledPostsComponent = styled.div`
  margin: 0 auto;
  max-width: 800px;
  padding-top: 220px;
`
