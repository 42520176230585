import React from 'react'
// import Image from 'gatsby-image'
// import { FaRegClock, FaTags } from 'react-icons/fa'
// import { BsTag } from 'react-icons/bs'
import { IoMdPricetags } from 'react-icons/io'
// import { IoMdArrowRoundForward } from 'react-icons/io'
import { Link } from 'gatsby'
import styled from 'styled-components'

const Post = ({ frontmatter, excerpt }) => {
  const { title, image, slug, date, category, readTime } = frontmatter
  return (
    <StyledPostComponent>
      {/* <Image fluid={image.childImageSharp.fluid} className="img" /> */}

      <Link to={`/posts/${slug}`} className="link">
        {/* Continue Reading <IoMdArrowRoundForward /> */}
        <h3>{title}</h3>
        {excerpt ? <p className="post-excerpt">{excerpt}</p> : ''}
      </Link>
      <div className="post-details">
        <div className="tag">
          <span className="tag-emoji">
            {/* <BsTag /> */}
            <IoMdPricetags />
          </span>
          <span className="category">{category}</span>
        </div>
        <span className="post-published-date">{date}</span>
      </div>
      {/* <div className="underline"></div> */}
    </StyledPostComponent>
  )
}

const StyledPostComponent = styled.div`
  padding: 20px 0;
  h3 {
    font-family: Rubik, sans-serif;
    font-size: 1.4rem;
    font-weight: 400;
    margin: 0 0 0.8rem;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        width: 10%;
        height: 3px;
        bottom: -5px;
        background-color: #f0db4f;
        visibility: hidden;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transition: all 0.3s ease-in-out 0s; 
        transition: all 0.3s ease-in-out 0s; 
    }   

    &:hover {
        color: #3a3335 !important;

        &::before {
            visibility: visible;
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }   
    }   
  }
  a {
    color: #3a3335;
    text-decoration: none;

    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
      text-decoration: none;
    }
  }
  .post-excerpt {
    color: #757575 !important;
    font-family: Rubik, sans-serif;
    font-size: 1rem;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 18px;
    margin-top: 4px;
    word-break: break-word;

    &:hover {
      color: #757575 !important;
    }
  }

  .post-details {
    display: flex;
    justify-content: space-between;
    font-size: 0.8em;
    color: #2d3748;
    font-family: Rubik, sans-serif;
    font-weight: 300;
    text-transform: lowercase;
    padding-bottom: 15px;
  }

  .post-published-date {
    font-family: 'Roboto Mono', monospace;
  }

  .tag-emoji {
    margin-right: 12px;
    font-size: 1.2em;
  }

  .category {
    font-family: 'Roboto Mono', monospace;
    background-color: #f0db4f;
    // background-color: #62bfed;
    // height: 20px;
    //   border: 1px solid #000;
    //   border-radius: 22px;
    //   padding: 2px 10px;
  }
  .tag {
    margin: 0;
    padding: 0;
    display: flex;
    // line-height: 1.3;
  }
`

export default Post
